import Vue from 'vue'
import './set-public-path'
import App from './App.vue'
import VueI18n from "vue-i18n";
import EN from "./localization/en";
import router from "./router";
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Multiselect from 'vue-multiselect'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import commonMixin from '../src/assets/common/vue-common.js'
import ListingcommonMixin from '../src/assets/common/vue-commonList.js'
 import ModuleHeader from '../src/components/Header/ModuleHeader.vue';
import breadcrumb from '@/components/Common/BreadCrumb.vue'
import Pager from '@/components/Common/Pager.vue'
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useloggedUserInfo } from '../src/stores/UserInfo';
//import VueCompositionAPI from '@vue/composition-api'
import singleSpaVue from 'single-spa-vue';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import Loader from './components/Common/Loader.vue'
import Vue2ColorPicker from 'vue2-color-picker';
import moment from 'moment';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import vuescroll from 'vuescroll';
 var $ = require('jquery');
//import draggable from "vuedraggable";
import Popover from 'vue-js-popover';
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js'
//import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css';
import VueGridLayout from 'vue-grid-layout';
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';
import {TgList} from 'tg-listv/dist/tglistv.common'
import { DynamicForm } from 'tg-controls_cli/dist/myLib.common'
import VueApexCharts from 'vue-apexcharts'
import TypeAhead from 'vue2-typeahead'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import {BTab, BTabs} from "bootstrap-vue"
// import './assets/base.css'
Vue.config.productionTip = false;
Vue.prototype.$= $;
//require('./vee-validate');
 //Vue.use(VueCompositionAPI)
 Vue.use(VueI18n);
 Vue.use(PiniaVuePlugin);
 Vue.use(CKEditor);
 Vue.use(Popover,{ tooltip: true })
 Vue.use(VueApexCharts);
 Vue.use(PerfectScrollbar);
 //Vue.use(VueGridLayout);
 Vue.component('TypeAhead',TypeAhead);
 Vue.component('grid-layout',VueGridLayout.GridLayout);
 Vue.component('grid-item',VueGridLayout.GridItem);
 Vue.component('TypeAhead',TypeAhead);
 Vue.component('b-tabs',BTabs);
 Vue.component('b-tab',BTab);
 Vue.component('VueApexCharts', VueApexCharts)
 //Vue.component('draggable',draggable);
 Vue.component('v-date-picker', DatePicker)
 Vue.component('loader', Loader)
 Vue.component('breadcrumb', breadcrumb);
 Vue.component('ModuleHeader',ModuleHeader);
Vue.component('Pager',Pager);
 Vue.component('Multiselect', Multiselect);
 Vue.component('tg-list',TgList);
 Vue.component('dynamic-form',DynamicForm);
 Vue.use(VueTelInput);
 Vue.use(Vue2ColorPicker);

 Vue.use(vuescroll); 
 Vue.use(VueEasyLightbox);
 Vue.component(Skeletor.name, Skeletor);
 const pinia=createPinia();
 window.pinia = pinia;
 Vue.use(pinia);
const i18n = new VueI18n({
  locale: "en",
  messages: {
    en:EN,
  },
});
Vue.mixin(commonMixin, ListingcommonMixin);
Vue.filter('formatDate', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('MM/DD/YYYY'))
}
})
Vue.filter('formatTime', function (value){
  if (value) {
    return moment(value, "HH:mm:ss").format(String('h:mm A'));
}
})
Vue.filter('formatDateTime', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD HH:mm:ss").format(String('MM/DD/YYYY h:mm A'))
}
})
Vue.filter('currency', function (value){
  if (value) {
    var n = Number.parseFloat(value);
    if (!value || isNaN(n) || n < 0) return 0;
    return n.toFixed(2);
} else {
    return value;
}
})
Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
      return text.substring(0, length) + suffix;
  } else {
      return text;
  }
});
const containerSelector = '#page-2-container'
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    pinia,
    i18n,
    router,
    render(h) {     
      return h(App, {
         props: {          
          props: {          
            userProfile:useloggedUserInfo().setUser(this.userProfile) 
           } 
        } 
      });//
    },
    el: containerSelector
    
  }
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
